import React from 'react';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Arrow} from '~/common/svg/PB_Arrow';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';

export const PanelHeaderRow = ({
  title,
  onClose,
  qaId,
  backArrow = false,
  titleVariant = titleVariant || 'list2',
}) => {
  const capitalizedTitle = () => {
    // todo handle this more elegantly
    // Removes dashes & capitalizes first letters
    const headerTitle = title.replace('-', ' ');

    const titleArray = headerTitle.split(' ');
    for (let i = 0; i < titleArray.length; i++) {
      titleArray[i] = titleArray[i].charAt(0).toUpperCase() + titleArray[i].slice(1);
    }

    const splitString = titleArray.join(' ');
    return splitString.toString();
  };

  if (backArrow) {
    return (
      <div className="panel-header-row">
        <Button
          variant="transparent"
          onClick={() => onClose()}
          data-qa-id={`${qaId}_back_button`}
          type="button"
        >
          <PB_Arrow direction="left" ratio={0.75} />
        </Button>
        <Typography variant={titleVariant} size="large">
          {capitalizedTitle()}
        </Typography>
      </div>
    );
  }

  return (
    <div className="panel-header-row">
      <Typography variant={titleVariant} size="large">
        {capitalizedTitle()}
      </Typography>
      <Button
        variant="transparent"
        onClick={() => onClose()}
        data-qa-id={`${qaId}_close_button`}
        type="button"
      >
        <PB_CloseLine />
      </Button>
    </div>
  );
};
