import {UPDATE_INVITATION_SUMMARY} from './constants';

const initialState = {
  displayOrder: [],
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      const orderedModules = Object.entries(action.results)
        .map(([m, v]) => [m, v.display_order])
        .filter(([m, v]) => v !== undefined)
        .sort(([m, v], [m2, v2]) => v - v2)
        .map(([m, v]) => m);

      // If both donation and donations are defined, just use donation;
      // data wrangling is handled in donation reducer and stored in state.donation
      if (orderedModules.includes('donation') && orderedModules.includes('donations')) {
        const idx = orderedModules.findIndex((m) => m === 'donations');
        if (idx >= 0) {
          orderedModules.splice(idx, 1);
        }
      } else if (orderedModules.includes('donations')) {
        // If only donations is defined, swap it out with donation
        const idx = orderedModules.findIndex((m) => m === 'donations');
        if (idx >= 0) {
          orderedModules.splice(idx, 1, 'donation');
        }
      }
      return {
        displayOrder: orderedModules,
      };
    default:
      return state;
  }
}
