import urls from 'urls';
import {EviteModal} from './index';

// Example simple usage:
//
//  delete_modal = new ModalDialog
//    title:      'Delete Group'
//    body:       'Are you sure you wish to delete this group?'
//    buttons:    ['Cancel', 'Delete Group']
//    onResponse: (response) ->
//      if response.index == 1
//        console.log 'delete group'
//  delete_modal.show()

export class ModalDialog {
  settings = {
    url: urls.get('modal_dialog'),
    buttons: ['Cancel', 'Yes'],
    title: null,
    html: false,
    width: '480px',
    dialog: {},
    footer: false,
    eventId: null,
  };

  constructor(settings) {
    if (!this.settings.url_parameters) {
      this.settings.url_parameters = {};
    }
    this.settings.url_parameters.dialog = {
      body: settings.body || '',
      title: settings.title || this.settings.title,
      buttons: settings.buttons || this.settings.buttons,
      html: settings.html || this.settings.html,
      footer: settings.footer || this.settings.footer,
    };
    return new EviteModal({...this.settings, ...settings});
  }
}
