import XDate from 'xdate';

export const cityAddressLine = (event) => {
  let line = '';

  if (event.city) line += `${event.city}, `;

  if (event.state) line += event.state;

  if (event.zip_code) line += ` ${event.zip_code}`;

  return line;
};

export const cityZip = (event) => {
  let cityZip = '';
  if (event.city) {
    cityZip += event.city;
    if (event.state || event.zip_code) cityZip += ', ';
  }
  if (event.state) {
    cityZip += event.state;
    if (event.zip_code) cityZip += ' ';
  }
  if (event.zip_code) cityZip += ` ${event.zip_code}`;
  return cityZip;
};

// convert to a valid ISO string format from a date string
// that is formatted like "20171114T193000"
export const convertToIso = (dateTimeStr) => {
  const isAlreadyFormatted = !dateTimeStr || (dateTimeStr && dateTimeStr.split('-').length > 2);
  if (isAlreadyFormatted) return dateTimeStr;

  const eviteDatePattern = /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/;
  return dateTimeStr.replace(
    eviteDatePattern,
    (match, year, month, date, hour, min, sec) => `${year}-${month}-${date}T${hour}:${min}:${sec}`
  );
};

export const templateDates = (_startDateTime, _endDateTime, timezone, dateFormat, timeFormat) => {
  const startDateTime = new XDate(_startDateTime || '');
  const endDateTime = new XDate(_endDateTime || '');
  const dateFormatStr = dateFormat || 'dddd, MMMM d ';
  const timeFormatStr = timeFormat || 'h:mm TT';

  const isValidStart = startDateTime.toString('i') !== 'Invalid Date';
  const isValidEnd = endDateTime.toString('i') !== 'Invalid Date';

  let startDateStr;
  let startTimeStr = '';
  let datesLineOne = '';
  if (isValidStart) {
    startDateStr = startDateTime.toString(dateFormatStr);
    if (_startDateTime.length >= 16) startTimeStr = startDateTime.toString(timeFormatStr);
    datesLineOne = `${startDateStr} at ${startTimeStr} ${!isValidEnd ? ` ${timezone}` : ''}`;
  }

  let datesLineTwo = '';
  if (isValidEnd) {
    datesLineOne = datesLineOne.replace(' at', ' from');

    const endDateStr = endDateTime.toString(dateFormatStr);
    const endTimeStr = _endDateTime.length >= 16 ? endDateTime.toString(timeFormatStr) : '';

    if (isValidStart && startDateStr === endDateStr) {
      datesLineTwo = ` to ${endTimeStr} ${timezone}`;
    } else {
      datesLineTwo = ` to ${endDateStr} at ${endTimeStr} ${timezone}`;
    }
  }
  // Monday, May 10 from 6:00 PM to 10:00 PM PT
  // Monday, May 10 from 10:00 PM to Tuesday, May 11 at 2:00 AM PT
  return {start_formatted: datesLineOne, end_formatted: datesLineTwo};
};

export const inviteStatus = () => {
  // Use 'Sent' for cards, and 'Invited' for invitations
  if (window.is_invite === false) return 'Sent';
  return 'Invited';
};

export const guestTitle = () => {
  // Use 'Recipient' for cards, and 'Guest' for invitations
  if (window.is_invite === false) return 'Recipient';
  return 'Guest';
};

export function formatCents(cents) {
  let neg = '';
  if (cents < 0) {
    neg = '-';
    cents *= -1;
  }
  cents = (cents < 10 ? '0' : '') + cents;
  return `${neg}$${cents.substring(0, cents.length - 2)}.${cents.substring(cents.length - 2)}`;
}

/*
  Display the year only if it is not the current year,
  and end date only if its a different day.
*/
export function dateTimesWithCurrentYear(event) {
  if (!event.start_datetime) return {start_formatted: '', end_formatted: ''};

  try {
    const currentYear = new Date().getFullYear();
    const startYear = Number.parseInt(event.start_datetime.substring(0, 4), 10);
    const dateFormat = currentYear === startYear ? 'dddd, MMMM d' : 'dddd, MMMM d, yyyy';
    const timezone = event.known_timezone_abbr;

    const {start_formatted, end_formatted} = templateDates(
      convertToIso(event.start_datetime),
      convertToIso(event.end_datetime),
      timezone,
      dateFormat
    );
    return {start_formatted, end_formatted};
  } catch (e) {
    evite.warn(`Failed to parse date: ${e}`);
  }
}
