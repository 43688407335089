let _ROKT_PRELOADED = false;

export const preloadROKT = () => {
  // loads the rokt script and precache-iframe.
  if (_ROKT_PRELOADED) return;
  _ROKT_PRELOADED = true;

  (function (r, o, k, t, n, e, w, a, _) {
    r._ROKT_ = n;
    r[n] = r[n] || {
      id: t,
      h: w,
      lc: [],
      it: new Date(),
      onLoaded(c) {
        r[n].lc.push(c);
      },
    };
    a = o.createElement('script');
    a.type = 'text/javascript';
    a.async = !0;
    a.src = k;
    if (e) {
      a.integrity = e;
      a.crossOrigin = 'anonymous';
    }
    _ = o.getElementsByTagName('script')[0];
    _.parentNode.insertBefore(a, _);
  })(
    window,
    document,
    'https://apps.rokt.com/wsdk/integrations/snippet.js',
    '2835341786096105351_cd615db4e85f48379cb3eba27c24f750',
    'rokt'
  );
  rokt.onLoaded((rokt) => {
    rokt.init({skipInitialSelection: true});

    rokt.onFailure((reason) => {
      console.error('Rokt failed to load', reason);
    });
  });

  const iframe = document.createElement('iframe');
  iframe['aria-hidden'] = true;
  iframe.src = 'https://apps.rokt.com/wsdk/preload/index.html';
  iframe['allow-same-origin'] = true;
  iframe.style = 'border: 0px; width: 100%; display: none;';
  document.body.appendChild(iframe);
};

export const triggerROKTOverlay = (pageIdentifier, attributes, clientData) => {
  preloadROKT(); // Just in case... the preload should have been called before the trigger.
  if (!window.rokt) {
    console.error('Rokt library does not exist');
    return;
  }
  window.rokt.onLoaded((rokt) => {
    const cd = clientData || window.client_data;
    rokt.setAttributes({
      // Recommended
      emailmd5: attributes.emailMd5,
      emailsha256: attributes.emailSha256,
      zipcode: cd.user_zip_code,
      city: cd.geo_city, // user city, as opposed to event city
      state: cd.geo_region,
      country: cd.geo_country,
      // Miscellaneous
      conversiontype: cd.event_type,
      clientcustomerid: attributes.userId,
      eventid: cd.event_id,
      eventcity: cd.event_city,
      eventstate: cd.event_state,
      eventzipcode: cd.event_zip,
      eventdate: cd.event_day ? cd.event_day.replace(/-/g, '') : null,
      distance: cd.event_guest_distance,
      userstatus: cd.event_step, // H or G for host or guest
      daysaway: cd.event_days_until,
      sandbox: window.roktSandbox,
    });
    rokt.onPositiveEngagement(() => {
      rokt.setAttributes({
        email: attributes.email,
      });
    });
    rokt.triggerPageChange({
      pageIdentifier: (window.roktSandbox ? 'staging-' : '') + pageIdentifier,
    });
  });
};

export const cleanupROKTOverlay = () => {
  // Close any ROKT overlays
  window.rokt &&
    window.rokt.onLoaded((rokt) => {
      rokt.closeAll();
    });
};
