import {UPDATE_INVITATION_SUMMARY, POLL_VOTE_UPDATED, SUBMIT_RSVP} from './constants';
import {INIT_APP} from '~/common/fabric/constants';
import {CREATE_EDIT_POLL} from '~/common/fabric/reducers/widgets';

const initialState = {
  question: '',
  options: [],
  Id: 0,
  optionSelectedByUser: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        ...window?.event_widgets?.polls?.[0],
      };
    case CREATE_EDIT_POLL:
      return {
        ...state,
        ...action?.payload?.polls?.[0],
      };
    case POLL_VOTE_UPDATED:
      state = action.results;
      return state;
    case SUBMIT_RSVP:
      return {
        ...state,
        hasSubmittedRSVP: true,
      };
    case UPDATE_INVITATION_SUMMARY:
      const {poll} = action.results;
      if (!poll || !poll.question || !poll.id) return {initialState};
      return {
        ...poll,
      };
    default:
      return state;
  }
}
