import {UPDATE_INVITATION_SUMMARY} from './constants';

const initialState = {
  display_order: null,
  registries: [
    /* {vendor_name: "", url: "", icon_url: ""} */
  ],
  name: '',
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      return action.results?.gift_registry ?? {};
    default:
      return state;
  }
}
