/* public-path Sets the cdn path of the chunks.  Must come first in this file */
import '~/common/public-path';

import React, {lazy, Suspense, useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {useSelector, Provider} from 'react-redux';
import {Router, Route, Switch, Redirect, useLocation} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import {ModalProvider} from 'react-modal-hook';
import {TransitionGroup} from 'react-transition-group';
import {reducer as giftKeywordsReducer} from '../common/gift-keywords/reducer';
import {createInvitationStore} from './reducers';
import {initInvitationSummary} from './reducers/apiActions';
import {initUser} from './reducers/user';
import {initCoverData} from './reducers/coverData';
import {initPremiumDesign} from './reducers/premiumDesign';
import {initRsvp} from './reducers/rsvp';
import {useTrackPages, trackRouteChange, extractTab} from './utils/trackPages';
import {history} from './history';
import {preloadROKT} from '~/common/utils/ROKT';
import {isPreview} from '~/ViewInvitation/utils';
import {StickyEditEvent} from '~/ViewInvitation/common/StickyEditEvent';
import {selectEventHasRsvp, setEviteEvent} from './reducers/eventDetails';

import 'react-toastify/dist/ReactToastify.min.css';

const Messages = lazy(() =>
  import('./routes/Messages').then((module) => ({default: module.Messages}))
);

const AllComments = lazy(() =>
  import('./routes/Photos/AllComments').then((module) => ({default: module.AllComments}))
);
const Photos = lazy(() =>
  import('./routes/Photos/Photos').then((module) => ({default: module.Photos}))
);
const Confirmation = lazy(() =>
  import('./routes/Confirmation').then((module) => ({default: module.Confirmation}))
);
const PickGiftKeywords = lazy(() =>
  import('./routes/Gifts').then((module) => ({default: module.PickGiftKeywords}))
);
const LandingPage = lazy(() =>
  import('./routes/Landing').then((module) => ({default: module.LandingPage}))
);

const TrackRouteChanges = () => {
  useTrackPages();
  return null;
};

const Redirector = () => {
  // Redirect based on query parameters on first render.
  const location = useLocation();
  const [firstRender, setFirstRender] = useState(true);
  const eventHasRsvp = useSelector(selectEventHasRsvp);
  const hasRsvpd = useSelector((theStore) => theStore.rsvp?.hasRsvpd);
  useEffect(() => {
    const tab = extractTab(location.pathname);
    trackRouteChange(tab);
    setFirstRender(false);
  }, []);

  let showStickyRsvp = false;

  // If not in preview mode, AND in messages module, AND can RSVP, and has NOT RSVP'd, auto-show sticky RSVP and auto-open RSVP modal
  const preview = isPreview();

  if (location.pathname !== '/messages' && !location.pathname.startsWith('/messages/')) {
    showStickyRsvp = false;
  } else if (!showStickyRsvp) {
    // this condition is here so we don't auto-remove the sticky RSVP when
    // the guest RSVP's; this is to make sure the redirect in RSVPModal
    // still occurs
    showStickyRsvp = !preview && eventHasRsvp && !hasRsvpd;
  }

  if (showStickyRsvp) {
    return <StickyEditEvent forceShow />;
  }

  if (firstRender) {
    const params = new URLSearchParams(location.search);
    const utmCampaign = params.get('utm_campaign');
    const state = {fromEmail: true};

    if (location.pathname === '/gifts' && !window.evite_event?.is_host_viewing)
      // Mobile Guests are redirected away from the gift page url...
      return (
        <Redirect
          to={{
            pathname: '/#gift-keywords',
            search: params.toString(),
            state,
          }}
        />
      );

    if (eventHasRsvp && utmCampaign) {
      ['yes', 'maybe', 'no'].forEach((response) => {
        if (utmCampaign.indexOf(`${response}_bt`) >= 0) {
          state.rsvp = response;
        }
      });

      if (state.rsvp) {
        params.delete('utm_campaign');
        return <Redirect to={{pathname: '/rsvp', search: params.toString(), state}} />;
      }
    }
  }
  return null;
};

const ViewInvitationApp = ({readonly, store}) => {
  useEffect(() => {
    preloadROKT();
  }, []);

  return (
    <Provider store={store}>
      <ModalProvider rootComponent={TransitionGroup}>
        <Router history={history}>
          <TrackRouteChanges />
          {/* <StickyRSVP /> */}
          <Redirector />
          <Suspense
            fallback={
              <div
                style={{
                  minHeight: '60dvh',
                  minWidth: '100vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transform: 'scale(200%)',
                }}
              >
                <span className="evt-spinner inline" />
              </div>
            }
          >
            <Switch>
              <Route path="/gifts" component={PickGiftKeywords} />
              <Route path="/messages" component={Messages} />
              <Route path="/frp/:type" component={Confirmation} />
              <Route path="/photos?:query" component={AllComments} />
              {/* <Route path="/photos/select-photos" component={StagedPhotos} /> */}
              {/* <Route */}
              {/*  path="/photos/select-photos?:query" */}
              {/*  component={StagedPhotos} */}
              {/* /> */}
              <Route path="/photos/:postId" component={AllComments} />
              <Route path="/photos/:postId/?:query" component={AllComments} />
              <Route path="/photos" component={Photos} />
              <Route
                path="/"
                render={(props) => (
                  <LandingPage {...props} readonly={readonly} isViewInvitationPage />
                )}
              />
              <Route
                path="*"
                render={(props) => {
                  toast(<p>URL {props.match.url} not found - redirecting</p>);
                  return <Redirect to="/" />;
                }}
              />
            </Switch>
          </Suspense>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={6000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ModalProvider>
    </Provider>
  );
};
window.toast = toast;

const root = createRoot(document.getElementById('inviteRoot'));
evite.when(['react', 'site-libraries']).then(() => {
  const store = createInvitationStore({});
  initInvitationSummary(store);
  store.dispatch(initUser(window.viewingUser));
  store.dispatch(initCoverData(window.cover_data));
  store.dispatch(setEviteEvent(window.evite_event));
  store.dispatch(initRsvp(window.evite_event.rsvp));
  store.dispatch(giftKeywordsReducer);
  if (window.evite_event?.origin === 21) {
    if (window.evite_event?.options?.design) {
      store.dispatch(initPremiumDesign({premiumDesign: window.evite_event.options.design}));
    }
  } else if (window.inviteData) {
    store.dispatch(initPremiumDesign({inviteData: window.inviteData}));
  }
  root.render(<ViewInvitationApp readonly store={store} />);
});
