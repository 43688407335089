import {EviteModal} from './evite_modal';

export class PopupModal {
  settings = {
    buttons: ['Cancel', 'Yes'],
    buttonMarkup: '',
    html: false,
    title: null,
    body: '',
    width: '520px',
    popup: true,
  };

  constructor(settings) {
    return new EviteModal({...this.settings, ...settings});
  }
}
