import {
  fabricDesignWithCrossOriginUpdates,
  fabricDesignWithTextBorderObjectsRemoved,
} from '~/common/utils/event_utils';
import {silver} from '~sass/pb_styleguide/base/_exports.sass';
import {Sections} from '~/common/fabric/CreateDesign/constants';

const SET_FABRIC_DESIGN = 'ViewInvitation/fabricDesign/setFabricDesign';

const initialState = {
  originalFabricArt: {},
  fabricArt: {},
  backgroundId: null,
  backgroundColor: silver,
  stampId: 'evite01',
  envelopeId: 'wht01',
  linerId: '',
  envelopeStickers: null,
  currentSection: Sections.CARD_DESIGN,
  loaded: false,
  touched: false,
  pristine: true,
  pristineUyo: false,
  hasTextbox: true,
  editingMobile: false,
  editingUyoMobile: false,
  textAreaOpen: false,
  savingStep: false,
  forceShowOnboardingModal: false,
  uyoImagePath: '',
};

export function setFabricDesign(evite_event, template) {
  const payload = {
    event: evite_event,
    template,
  };
  return {type: SET_FABRIC_DESIGN, payload};
}

export const selectFabricArt = ({fabricDesign}) => fabricDesign.fabricArt;

export const selectEnvelopeStickers = ({fabricDesign}) => fabricDesign.envelopeStickers;

export const selectOriginalFabricArt = ({fabricDesign}) => fabricDesign.originalFabricArt;

export const selectBackgroundId = ({fabricDesign}) => fabricDesign.backgroundId;

export const selectBackgroundColor = ({fabricDesign}) => fabricDesign.backgroundColor;

export const selectStampId = ({fabricDesign}) => fabricDesign.stampId;

export const selectEnvelopeId = ({fabricDesign}) => fabricDesign.envelopeId || 'wht01';

export const selectLinerId = ({fabricDesign}) => fabricDesign.linerId;

export const selectCurrentSection = ({fabricDesign}) => fabricDesign.currentSection;

export const selectLoaded = ({fabricDesign}) => fabricDesign?.loaded ?? false;

export const selectTouched = ({fabricDesign}) => fabricDesign.touched;

export const selectPristine = ({fabricDesign}) => fabricDesign.pristine;
export const selectPristineUyo = ({fabricDesign}) => fabricDesign.pristineUyo;
export const selectIsPristineUyo = ({fabricDesign}) =>
  Object.values(fabricDesign.pristineUyo).some((v) => v === true);
export const selectCountOfUyoImages = (state) => {
  const objects =
    state.fabricDesign.originalFabricArt?.objects || state.eventDetails.fabric_design_data?.objects;
  return objects ? objects.filter((obj) => obj.type === 'uYOImage').length : 0;
};

export const selectHasTextbox = ({fabricDesign}) => fabricDesign.hasTextbox;

export const selectPremiumFeaturesPristine = ({fabricDesign}) =>
  fabricDesign.premiumFeaturesPristine;

export const selectPremiumFeaturesWarning = ({fabricDesign}) => fabricDesign.premiumFeaturesWarning;

export const selectIgnorePremiumWarning = ({fabricDesign}) => fabricDesign.ignorePremiumWarning;

export const selectSavingStep = ({fabricDesign}) => fabricDesign.savingStep;

export const selectTextAreaOpen = ({fabricDesign}) => fabricDesign.textAreaOpen;

export const selectEditingMobile = ({fabricDesign}) => fabricDesign.editingMobile;
export const selectEditorDisabled = ({fabricDesign}) => fabricDesign.editorDisabled;

export const selectEditingUyoMobile = ({fabricDesign}) => fabricDesign.editingUyoMobile;

export const selectForceShowOnboardingModal = ({fabricDesign}) =>
  fabricDesign.forceShowOnboardingModal;

export const selectSelectedEnvelope = (state) => {
  const {envelopeId} = state.fabricDesign;
  if (!envelopeId) return undefined;
  for (const envelope of state.fabricTemplate.allEnvelopes)
    if (envelope.id === envelopeId) return envelope;
  return state.fabricTemplate.allEnvelopes[0];
};

export const selectSelectedStamp = (state) => {
  const {stampId} = state.fabricDesign;
  if (!stampId) return undefined;
  for (const stamp of state.fabricTemplate.allStamps) if (stamp.id === stampId) return stamp;
  return state.fabricTemplate.allStamps[0];
};

export const selectSelectedLiner = (state) => {
  const {linerId} = state.fabricDesign;
  if (!linerId) return undefined;
  for (const liner of state.fabricTemplate.allLiners) if (liner.id === linerId) return liner;
};

export const selectSelectedBackground = (state) => {
  const {backgroundId} = state.fabricDesign;
  if (!backgroundId) return undefined;
  for (const bg of state.fabricTemplate.allBackgrounds) if (bg.id === backgroundId) return bg;
};

function _first_matching_art(art, category_list) {
  for (const c of category_list) if (c && art[c]) return art[c];
}

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_FABRIC_DESIGN: {
      const template = action.payload.template || {};
      const event = action.payload.event || {};
      const design = event?.options?.design;
      const fabricDesign =
        event?.fabric_design_data ??
        _first_matching_art(template?.editable_art, [
          action.payload.category_event_type,
          template.event_type,
          'default',
        ]);
      return {
        ...state,
        originalFabricArt: fabricDesignWithCrossOriginUpdates(
          _first_matching_art(template?.editable_art, [
            event?.event_type,
            action.payload.category_event_type,
            template.event_type,
            'default',
          ])
        ),

        fabricArt: fabricDesignWithTextBorderObjectsRemoved(
          fabricDesignWithCrossOriginUpdates(fabricDesign)
        ),
        backgroundId: design?.background ?? template?.backgrounds?.[0],
        backgroundColor: design?.background_color ?? template?.background_color ?? silver,
        stampId: design?.stamp ?? template?.stamps?.[0],
        envelopeId: design?.envelope ?? template?.envelopes?.[0],
        linerId: design?.liner ?? template?.liners?.[0],
        currentSection: Sections.CARD_DESIGN,
        envelopeStickers: design?.envelope_fabric_stickers,
        loaded: false,
        touched: !!event?.id,
        pristine: !event?.id,
        premiumFeaturesPristine: !event?.id,
        premiumFeaturesWarning: false,
        ignorePremiumWarning: !!event?.id,
        editingMobile: false,
        editorDisabled: !!event?.id,
        forceShowOnboardingModal: false,
      };
    }
    default:
      return state;
  }
};
