import {
  FETCH_WTB,
  WTB_UPDATE_SELECTIONS,
  WTB_RESET_SELECTIONS,
  WTB_TOGGLE_EDITING,
  WTB_UPDATED,
  WTB_ADDED_OTHER,
  WTB_REMOVE_OTHER,
} from './constants';

const initialState = {
  items: [
    // {
    //   id: '',
    //   name: '',
    //   quantity: 0,
    //   remaining: 10,
    //   selections: [
    //     {
    //       user: {
    //         id: '',
    //         first_name: '',
    //       },
    //       quantity: 5,
    //     }
    //   ],
    //   creator_id: '',
    // }
  ],
  allowOther: false,
  isEditing: false,
  pendingSelections: [],
  creatorId: '',
};

export function getItemQuantityForUser(itemId, userId) {
  return (state) => {
    const wtb = state.whatToBring;
    if (!wtb) return 0;
    const item = wtb.items.find((i) => i.id === itemId);
    if (!item) return 0;
    const selection = item.selections.find((s) => s.user?.id === userId);
    if (!selection) return 0;
    return selection.quantity;
  };
}

export function selectWTBItem(itemId) {
  /*
  Returns a consolidated WTB item
  {
    id:
    name:
    participantsList: [{guest_name, quantity, user_id}]
  }
   */
  return (state) => {
    const wtb = state.whatToBring;
    const item = wtb.items.find((i) => i.id === itemId);
    return {
      id: item.id,
      remaining: item.remaining,
      quantity: item.quantity,
      name: item.name,
      participantsList: item.selections.map((s) => ({
        guest_name: s.user.first_name,
        user_id: s.user.id,
        quantity: s.quantity,
      })),
    };
  };
}

export function reducer(state = initialState, action = {}) {
  let wtb = null;
  switch (action.type) {
    case FETCH_WTB:
      wtb = action.results.wtb_lists;
      if (!wtb) return state;

      return {
        ...state,
        creatorId: wtb[0].creator_id,
        allowOther: wtb[0].allow_other_items,
        items: wtb[0].items || [],
      };

    case WTB_TOGGLE_EDITING:
      return {...state, isEditing: !state.isEditing};

    case WTB_RESET_SELECTIONS:
      return {...state, isEditing: false, pendingSelections: []};

    case WTB_UPDATE_SELECTIONS:
      const {type, item} = action.payload;
      const {pendingSelections} = state;

      const foundSelection = pendingSelections.find((obj) => obj.id === item.id);

      if (type === 'add' && !foundSelection) {
        return {
          ...state,
          pendingSelections: [...pendingSelections, item],
        };
      }
      if (type === 'update' && foundSelection) {
        const updatedSelections = pendingSelections.map((sel) =>
          sel.id === item.id ? {...item} : sel
        );

        return {
          ...state,
          pendingSelections: updatedSelections,
        };
      }
      if (type === 'replace') {
        return {
          ...state,
          isEditing: true,
          pendingSelections: [{...action.payload.item}],
        };
      }
      if (type === 'remove') {
        const filteredSelections = pendingSelections.filter((obj) => obj.id !== item.id);
        return {
          ...state,
          pendingSelections: [...filteredSelections],
        };
      }
      return {...state};

    case WTB_UPDATED:
      wtb = action.results;
      if (!wtb) return state;

      return {
        ...state,
        creatorId: wtb.creator_id,
        allowOther: wtb.allow_other_items,
        items: wtb.items || [],
      };

    case WTB_ADDED_OTHER:
      wtb = action.results;
      return {
        ...state,
        creatorId: wtb.creator_id,
        allowOther: wtb.allow_other_items,
        items: wtb.items,
      };

    case WTB_REMOVE_OTHER:
      wtb = action.results;
      return {
        ...state,
        items: wtb.items,
      };

    default:
      return state;
  }
}
