import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {ORIGIN_DICT} from '~/common/constants/event-origins';

const YesButton = ({setWipRsvpStatus, setRsvpOverlayOpen}) => (
  <Button
    variant="primary"
    fullWidth
    data-qa-id="response-yes-rsvp"
    size="large"
    className="view-invitation__rsvp-cta"
    onClick={() => {
      setWipRsvpStatus('Yes');
      setRsvpOverlayOpen(true);
    }}
  >
    Yes
  </Button>
);

const NoButton = ({setWipRsvpStatus, setRsvpOverlayOpen, isInVariant = false}) => (
  <Button
    variant={isInVariant ? 'secondary' : 'primary'}
    fullWidth
    data-qa-id="response-no-rsvp"
    size="large"
    className="view-invitation__rsvp-cta"
    onClick={() => {
      setWipRsvpStatus('No');
      setRsvpOverlayOpen(true);
    }}
  >
    No
  </Button>
);

const GuestRsvpOriginalButtons = ({setWipRsvpStatus, setRsvpOverlayOpen, isPremium, origin}) => (
  <>
    <YesButton setWipRsvpStatus={setWipRsvpStatus} setRsvpOverlayOpen={setRsvpOverlayOpen} />
    {!isPremium && origin !== ORIGIN_DICT.FABRIC && (
      <Button
        variant="primary"
        fullWidth
        data-qa-id="response-maybe-rsvp"
        size="large"
        className="view-invitation__rsvp-cta"
        onClick={() => {
          setWipRsvpStatus('Maybe');
          setRsvpOverlayOpen(true);
        }}
      >
        Maybe
      </Button>
    )}
    <NoButton setWipRsvpStatus={setWipRsvpStatus} setRsvpOverlayOpen={setRsvpOverlayOpen} />
  </>
);

export const GuestRsvpActions = ({
  hasRsvpd,
  setUpdatingRsvp,
  setWipRsvpStatus,
  setRsvpOverlayOpen,
  isPremium,
  origin,
}) => (
  <div className="view-invitation__rsvp-handler">
    <Typography variant="label2" size="large" classNames="view-invitation__rsvp-heading">
      Will you be attending?
    </Typography>
    <div className="view-invitation__rsvp-cta-group">
      <GuestRsvpOriginalButtons
        origin={origin}
        setRsvpOverlayOpen={setRsvpOverlayOpen}
        setWipRsvpStatus={setWipRsvpStatus}
        isPremium={isPremium}
      />
    </div>
    {hasRsvpd && (
      <Button
        variant="unstyled-button"
        data-qa-id="cancel-rsvp-change"
        className="unstyled-button link"
        onClick={() => setUpdatingRsvp(false)}
      >
        Cancel
      </Button>
    )}
  </div>
);
