import {UPDATE_INVITATION_SUMMARY} from './constants';

const initialState = {};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      const {gifting} = action.results;
      if (!gifting) return {};
      // console.log('GIFTING', action.results);

      return {
        ...initialState,
        ...gifting,
      };
    default:
      return state;
  }
}
