import {INIT_PREMIUM_DESIGN} from './constants';

const initialState = {
  invitation: '',
  envelope: {},
  stamp: {},
  customImage: {},
  background: {},
  liner: {},
  inserts: [],
  envelopeStickers: null,
};

export function initPremiumDesign(payload) {
  return {
    type: INIT_PREMIUM_DESIGN,
    payload,
  };
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_PREMIUM_DESIGN:
      const {premiumDesign, inviteData} = action.payload;
      if (premiumDesign) {
        return {
          ...state,
          envelope: {
            name: {
              ...premiumDesign.name_style,
            },
            openBack: premiumDesign.envelope_flap_up,
            closedBack: premiumDesign.envelope_flap_down,
            closedFront: premiumDesign.envelope_front,
          },
          stamp: {
            image: premiumDesign.stamp_image,
          },
          background: {
            background: premiumDesign.background,
            color: premiumDesign.background_color,
            imageDesktop: premiumDesign.background_image_desktop,
            imageMobile: premiumDesign.background_image_mobile,
          },
          liner: {
            liner: premiumDesign.liner,
            image: premiumDesign.liner_image,
          },
          envelopeStickers: premiumDesign.envelope_fabric_stickers ?? null,
        };
      }
      if (inviteData) {
        return {
          ...state,
          invitation: inviteData.invitation,
          envelope: inviteData.envelope,
          stamp: inviteData.stamp,
          customImage: inviteData.customImage,
          inserts: inviteData.event.inserts,
        };
      }
    default:
      return state;
  }
}
