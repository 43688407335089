import React, {useState} from 'react';
import {useRecipientReaction} from './useRecipientReaction';
import {PB_AlertLine} from '~/common/svg/PB_AlertLine';
import {Button} from '~/common/_pb_components/atoms/Button';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {useEmojiBurstAnimation} from './useEmojiBurstAnimation';
import './GuestReaction.sass';

export const GuestReaction = ({
  rsvpDrawer,
  eventId,
  eviteType,
  guestId,
  saveReactionCallback = () => {},
}) => {
  const [reacted, setReacted] = useState(false);
  const [selectReaction, setSelectReaction] = useState(false);
  const {viewerIsNotHost} = window;

  const {animate} = useEmojiBurstAnimation(rsvpDrawer);
  const {submit} = useRecipientReaction(eventId, eviteType, guestId, saveReactionCallback);

  const handleReaction = async (e, reaction) => {
    if (!reacted) {
      setReacted(true);

      await animate(e, reaction);
      await submit(reaction).catch(() => setReacted(false));

      if (viewerIsNotHost) {
        document.querySelector('div.footer-wrap').style.paddingBottom = '0';
      }
    } else {
      toastAlert("You've already reacted to this event", {
        type: 'warning',
        icon: <PB_AlertLine size={0.75} />,
      });
    }
  };

  return reacted ? (
    <></>
  ) : selectReaction ? (
    <div className={`guest-reactions__select-reaction-wrapper ${reacted ? 'reacted' : ''}`}>
      <span id="emoji-fire" className="emoji emoji-fire" onClick={(e) => handleReaction(e, '🔥')}>
        🔥
      </span>
      <span id="emoji-thumb" className="emoji emoji-thumb" onClick={(e) => handleReaction(e, '👍')}>
        👍
      </span>
      <span id="emoji-party" className="emoji emoji-party" onClick={(e) => handleReaction(e, '🥳')}>
        🥳
      </span>
      <span id="emoji-heart" className="emoji emoji-heart" onClick={(e) => handleReaction(e, '💓')}>
        💓
      </span>
      <span
        id="emoji-popper"
        className="emoji emoji-popper"
        onClick={(e) => handleReaction(e, '🎉')}
      >
        🎉
      </span>
    </div>
  ) : (
    <div className="guest-reactions__reaction-wrapper">
      <Button
        variant="primary"
        fullWidth
        size="large"
        data-qa-id="send-reaction"
        onClick={() => setSelectReaction(true)}
      >
        Send Reaction
        <span className="guest-reactions__cycle-emoji">
          <span className="guest-reactions__emoji-fire">🔥</span>
          <span className="guest-reactions__emoji-party">🥳</span>
          <span className="guest-reactions__emoji-thumb">👍</span>
          <span className="guest-reactions__emoji-heart">💓</span>
          <span className="guest-reactions__emoji-popper">🎉</span>
        </span>
      </Button>
    </div>
  );
};
