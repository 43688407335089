import {history} from '../history';

// This file contains function functions operate on global state outside of the REDUX state tree.
// This includes window variables, url parameters, cookies, localState, messaging store

export const updateGuestId = (guestId) => {
  window.guest_id = guestId;

  // Update the URL parameters with the gid
  const {location} = history;
  const params = new URLSearchParams(location.search);
  params.set('gid', guestId);
  history.replace({pathname: location.pathname, search: `?${params}`}, location.state);
  const messaging = window.evite?.stores?.messaging;
  if (messaging) messaging.guestId = guestId;
};

export const updateUserId = (userId) => {
  window.user_id = userId;
  const messaging = window.evite?.stores?.messaging;
  if (messaging) messaging.userId = userId;
};

export const updateRsvp = (rsvp) => {
  // Update the Messaging store if it has been loaded.

  const messaging = window.evite?.stores?.messaging;
  if (messaging) messaging.rsvpResponse = rsvp;

  // Update the dataLayer
  const responseLookup = {YES: 'y', NO: 'n', MAYBE: 'm'};
  window.dataLayer.push({response: responseLookup[rsvp]});
};
