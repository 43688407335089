import {useLocation} from 'react-router-dom';
import {usePrevious} from '~/common/hooks/usePrevious';

export function extractTab(pathname) {
  const components = pathname.split('?')[0].split('/');
  if (components.length > 1) return components[components.length - 1] || '/';
  return '/';
}

export function trackRouteChange(tab) {
  window.setTimeout(() => {
    // DataOps use this to track pageviews within the app
    window.tags.push('view_tab_active', tab);
    window.dataLayer.push({
      event: 'pageview',
      destination_url: window.location.pathname,
    });
  }, 500);
}

/**
 * Reports top level route changes to google analytics.
 * @function useTrackPages
 * @return none
 */
export const useTrackPages = () => {
  const location = useLocation();
  const tab = extractTab(location.pathname);
  const previousTab = usePrevious(tab);
  if (previousTab !== tab && previousTab !== undefined) {
    trackRouteChange(tab);
  }
};
