import React from 'react';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {PanelHeaderRow} from '~/common/_pb_components/atoms/PanelHeaderRow';

export const SlideoutPanel = (props) => {
  const {matches: mdWindow} = useMatchQuery('(min-width: 48rem)', []);
  const {
    isOpen,
    onClose,
    direction = 'right',
    showHeader = true,
    title,
    qaId = 'slideout_panel',
    modalId,
    closeQaId,
    showCover,
  } = props;
  const titleVariant = props.titleVariant || 'list2';

  return (
    <>
      <button
        className={`transparent slideout-panel-overlay${showCover ? ' show-cover' : ''}${
          isOpen ? ' panel-open' : ''
        }`}
        onClick={() => onClose()}
        data-qa-id={closeQaId || `${modalId}-header`}
      />
      <div
        className={`slideout-panel-content slideout-panel-content${
          direction ? `--${direction}` : ''
        }${!mdWindow ? '--mobile' : ''}${isOpen ? ' open' : ''}`}
        data-qa-id={qaId}
      >
        {showHeader && (
          <PanelHeaderRow
            title={title}
            onClose={onClose}
            qaId={`${modalId}-header`}
            backArrow={props.backArrow}
            titleVariant={titleVariant}
          />
        )}
        {props.children}
      </div>
    </>
  );
};
