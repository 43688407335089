export const saveReaction = async (reaction, guestId, eventId) => {
  const url = `/ajax/guests/${eventId}/reaction`;
  const token = window.evite.cookie('csrftoken');

  const headers = {
    'X-CSRFToken': token,
    accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      guest_id: guestId,
      reaction,
    }),
  });

  if (!response.ok) {
    throw new Error('Error persisting reaction');
  }

  return response.json();
};
