import {FETCH_PHOTOS, FETCH_SINGLE_PHOTO_POST} from './constants';

const initialState = {
  photoPostIds: [],
  cursor: null,
  more: true,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PHOTOS:
      const photos = action.results.posts.filter((p) => p.post_type === 'single_photo');
      const {cursor} = action.results;
      const {more} = action.results;
      if (!photos) return {...state, cursor: null, more: true};

      return {
        ...state,
        cursor,
        more,
        photoPostIds: state.photoPostIds.concat(
          photos.map((p) => p.id).filter((id) => state.photoPostIds.indexOf(id) < 0)
        ),
      };
    case FETCH_SINGLE_PHOTO_POST: {
      const {postId} = action;
      if (state.photoPostIds.indexOf(postId) >= 0) return state;
      const photoPostIds = state.photoPostIds.slice();
      photoPostIds.unshift(postId);
      return {
        ...state,
        photoPostIds,
      };
    }

    default:
      return state;
  }
}
