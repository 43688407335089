export const DONE_SAVE_GIFT_KEYWORDS = 'DONE_SAVE_GIFT_KEYWORDS';

export const GIFT_KEYWORD_AMAZON_TAGS = {
  birthday: 'bday',
  birthday_forteens: 'bday_for_teens',
  birthday_forher: 'bday_for_her',
  birthday_forhim: 'bday_for_him',
  birthday_milestones: 'bday_milestones',
  birthday_save_the_date: 'bday_savethedate',
  birthday_forkids: 'bday_for_kids',
};

export const GIFT_KEYWORD_PLACEHOLDERS = [
  'Lego',
  'Soccer',
  'Arts & Crafts',
  'Books',
  'Games & Puzzles',
];
