import cx from 'classnames';
import PropTypes from 'prop-types';

export const Checkbox = ({
  checked,
  disabled = false,
  id = '',
  inline = false,
  name = '',
  onChange,
  label = '',
  labelVariant = 'paragraph2',
  labelClassName,
  className,
  inputRef = null,
  ...props
}) => {
  const styles = {};
  if (inline) styles.display = 'inline';
  return (
    <div style={styles} className={className}>
      <label
        htmlFor={id}
        className={cx(`${labelVariant} checkbox-wrapper`, {[labelClassName]: !!labelClassName})}
      >
        <input
          type="checkbox"
          className="checkbox"
          id={id}
          data-qa-id={props['data-qa-id'] || id}
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          data-checked={checked}
          ref={inputRef}
        />
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelVariant: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  'data-qa-id': PropTypes.string.isRequired,
  'data-qa-value': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};
