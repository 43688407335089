import * as actions from './constants';

const INIT_RSVP = 'ViewInvitation/rsvp/init';

const initialState = {
  hasRsvpd: false,
  rsvpResponse: '',
  guestEmail: '',
  emailMd5: '',
  emailSha256: '',
  totalAdults: 1,
  totalKids: 0,
  guestOf: '',
  comment: '',
  name: '',
  optin: false,
  showNotifyMarketing: true,
  inGuestlist: false,
  inviteMethod: '',
  notifications: {},
};

export function initRsvp(rsvp) {
  const cd = window.client_data;
  return {
    type: INIT_RSVP,
    rsvp,
    emailMd5: cd.evc_h,
    emailSha256: cd.evc_s256l,
  };
}

function hasRsvpd(response) {
  return ['yes', 'no', 'maybe'].indexOf(response) >= 0;
}

export function reducer(state = initialState, action = {}) {
  let rsvp;
  const notifications = action?.results?.notifications;
  switch (action.type) {
    case INIT_RSVP:
      rsvp = action.rsvp;
      if (!rsvp) return state;
      return {
        ...state,
        hasRsvpd: hasRsvpd(rsvp.response),
        rsvpResponse: rsvp.response,
        guestEmail: rsvp.email,
        totalAdults: rsvp.number_of_adults,
        totalKids: rsvp.number_of_kids,
        comment: rsvp.comment,
        name: rsvp.name,
        optin: !!rsvp.optin,
        optout: !!rsvp.optout,
        showNotifyMarketing: !rsvp.optin,
        inGuestlist: Boolean(rsvp.id),
        inviteMethod: rsvp.invite_method,
        notifications: {...rsvp.notifications},
        emailMd5: action.emailMd5,
        emailSha256: action.emailSha256,
      };

    case actions.SUBMIT_RSVP:
      rsvp = action.results.guest;
      return {
        ...state,
        hasRsvpd: hasRsvpd(rsvp.response),
        rsvpResponse: rsvp.response,
        guestEmail: rsvp.email,
        totalAdults: rsvp.number_of_adults,
        totalKids: rsvp.number_of_kids,
        comment: rsvp.comments,
        name: rsvp.name,
        optin: !!rsvp.optin,
        showNotifyMarketing: !rsvp.optin,
        inGuestlist: Boolean(rsvp.id),
        inviteMethod: rsvp.invite_method,
        notifications: {...rsvp.notifications},
        emailMd5: action.results.email_md5,
        emailSha256: action.results.email_sha256,
      };
    case actions.SUBMIT_RSVP_FAILED:
      return state;
    case actions.GUEST_NOTIFICATIONS_UPDATED:
      return {
        ...state,
        notifications,
      };
    default:
      return state;
  }
}
