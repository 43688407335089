import XDate from 'xdate';
import {CaptchaDialog} from '~/modal';
import {modal_ajax_error_handler} from '~/modal/_ajax_error_handler';
import {convertToIso} from '~/create/utils/formatters';
import {isCanceled} from '~/common/utils/event_utils';

export function eventHasPassed() {
  return !!window.event_has_passed;
}

export function isPremium() {
  return !!window.is_premium;
}

export function isMobile() {
  return !!window.is_mobile_site;
}

export function hasAds() {
  return !isPremium() && !window.evite_event.remove_ads;
}

export function isCard() {
  return !!window.is_card;
}

export function isHost() {
  return !!window.evite_event.is_host_viewing;
}

export function canSeeGuests() {
  return !window.evite_event.options.event_option_private_guestlist;
}

export function canRSVP() {
  return !isCard() && !eventHasPassed() && !isCanceled(window.evite_event.status);
}

export const isPreview = () => !!window.is_preview;

export const isFullPreview = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('preview') === '1';
};

export function handleCaptchaJsonResponse(responseJson) {
  return new Promise((resolve) => {
    new CaptchaDialog({
      challenge: responseJson,
      onSubmit: (_response) => {
        const captchaResponse = {..._response};
        captchaResponse.name = undefined;
        resolve(captchaResponse);
      },
    }).show();
  });
}

export function handleCaptcha(xhr, status, data, callback) {
  if (xhr && xhr.status === 412) {
    const response = JSON.parse(xhr.responseText);
    new CaptchaDialog({
      challenge: response,
      onSubmit(captcha) {
        captcha.name = void 0;
        captcha = JSON.stringify(captcha);
        callback(captcha);
      },
    }).show();
  } else {
    renderAjaxErrorModal(xhr, status, data);
    if (callback) {
      callback('');
    }
  }
}

export function renderAjaxErrorModal(xhr, status, data) {
  const hasStatus = xhr && xhr.status;
  const isServerError = xhr && xhr.status >= 500 && xhr.status < 600;

  if (hasStatus && (isMobile() || isServerError)) {
    let message = 'Sorry, an error has occurred';
    let responseTextMessage;

    try {
      responseTextMessage = JSON.parse(xhr.responseText).message;
    } catch (error) {
      responseTextMessage = xhr.responseText;
    }

    if (isServerError) {
      const consoleTitle = typeof data === 'string' ? data : 'Internal Server Error';
      if (responseTextMessage && console && console.error) {
        console.error(`${consoleTitle}:\n\n${responseTextMessage}\n`);
      }
      message = 'Sorry, an internal server error has occurred';
    } else if (responseTextMessage) {
      message = responseTextMessage;
    }

    alert(message);
  } else {
    modal_ajax_error_handler(xhr, status, data);
  }
}

export function fixupAbsoluteURL(url) {
  // Adds https:// to url if needed
  if (url && !url.match(/https?:\/\//i)) {
    if (url[0] !== '/') url = `//${url}`;
    url = `https:${url}`;
  }
  return url;
}
