import urls from 'urls';
import {EviteModal} from './evite_modal';

export class SendMessageModal {
  settings = {
    clickOutside: false,
    allowClose: false,
    width: '800px',
    title: 'Send a Message',
    cancel: 'Cancel',
    subject: 'Message about {subject}',
    className: 'send-message-modal',
    dataQaId: 'send-message-modal',
    message: '',
    guests: [],
  };

  constructor(settings) {
    this.settings = {
      ...this.settings,
      ...settings,
    };
    this.settings.url_parameters = {
      subject: this.settings.subject,
      message: this.settings.message,
      title: this.settings.title,
      cancel: this.settings.cancel,
      template: this.settings.template,
    };
    this.settings.url = urls.get('send_message_modal', {
      event_id: settings.event_id || window.event_id,
    });
    return new EviteModal(this.settings);
  }
}
