export const DEFAULT_CARD_ZOOM = 0.5;
export const DEFAULT_WIDTH_DESKTOP = 500;
export const DEFAULT_HEIGHT_DESKTOP = 680;
export const DEFAULT_WIDTH_MW = 265;
export const DEFAULT_HEIGHT_MW = 360;
export const DEFAULT_WIDTH_MINIFIED = 132;
export const DEFAULT_HEIGHT_MINIFIED = 180;

export const Sections = {
  CARD_DESIGN: 'card_design',
  ENVELOPE_LINER: 'enveloper_line',
  STAMP: 'stamp',
  BACKGROUND: 'background',
};

export const menuMappingKeys = {
  KEYBOARD: 'KEYBOARD',
  FONT_STYLE: 'FONT_STYLE',
  FONT_SIZE: 'FONT_SIZE',
  TEXT_FORMAT: 'TEXT_FORMAT',
  SPACING: 'SPACING',
  COLOR: 'COLOR',
  UYO: 'UYO',
};

export const RECOMMENDED_FONTS = [
  {
    family: 'BrandonGrotesqueMedium',
    image: '',
    name: 'Brandon Grotesque Medium',
    style: 'normal',
    type: 'sans-serif',
    url: '/assets/fonts/BrandonGrotesqueMedium.css',
    weight: 'normal',
  },
  {
    family: 'Vollkorn',
    image: '',
    name: 'Vollkorn Regular',
    style: 'normal',
    type: 'serif',
    url: 'https://fonts.googleapis.com/css2?family=Vollkorn&display=swap',
    weight: '400',
  },
  {
    family: 'la-bohemienne',
    image: '',
    name: 'La Bohemienne',
    style: 'normal',
    type: 'script',
    url: '',
    weight: '400',
  },
];

export const desktopColors = [
  '#101B21',
  '#F5F3F2',
  '#E092FF',
  '#FF6348',
  '#CA6E2E',
  '#5352EB',
  '#709FFF',
  '#B9BCBF',
  '#E1E3E6',
  '#FFF5ED',
  '#FFECE9',
  '#F4E2D5',
  '#EBEAFD',
  '#E2ECFF',
  '#B6AB94',
  '#7E7458',
  '#B1A68C',
  '#EBD0BA',
  '#943E1E',
  '#F5C3A6',
  '#BA9B74',
];

export const mobileColors = [
  '#101B21',
  '#F5F3F2',
  '#B71C1C',
  '#4A148C',
  '#1A237E',
  '#004D40',
  '#F57F17',
  '#FDD835',
  '#263238',
  '#E91E63',
  '#00BCD4',
  '#5C6BC0',
];
