import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import './Swirl.sass';

export const Swirl = (props) => {
  const src = props.src || '/assets/images/swirl.svg';

  return (
    <div className="swirl__animation" data-qa-id={props.qaId} onClick={props.onClick}>
      <img src={src} alt="" className="swirl__image" data-qa-id={props.qaId} />
      <Typography variant="swirl__text">{props.text}</Typography>
    </div>
  );
};
