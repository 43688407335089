import {SET_EVITE_EVENT, EVENT_OPTIONS_UPDATED} from './constants';

const initialState = {};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EVITE_EVENT:
      return {
        ...state,
        ...action.evite_event,
      };
    case EVENT_OPTIONS_UPDATED:
      return {
        ...state,
        options: action.payload,
      };
    default: {
      return state;
    }
  }
}
