import {ModalAjaxError} from './index';
import {PopupModal} from '~/modal/popup_modal';

export function modal_ajax_error_handler(xhr, status, errorstr, body, title) {
  let modalBody;
  const modalTitle = title || 'Error';

  if (body) {
    modalBody = body;
  } else {
    try {
      modalBody = JSON.parse(xhr.responseText).message;
    } catch (error) {
      console.error('Modal err:', error);
      console.error('Modal response:', xhr);
      modalBody = 'Sorry, an error has occurred!';
    }
  }

  new ModalAjaxError({
    title: modalTitle,
    body: modalBody,
    popup: true,
    buttons: ['Okay'],
    url_parameters: {
      response: xhr && xhr.responseText ? xhr.responseText : '',
      status,
      error: errorstr,
    },
  }).show();
}

/**
 * Show a modal dialog for a failed fetch. Should not require a server page render
 *
 * @param response
 * @param title
 * @param body
 */
export async function failedFetchModal(response, title = 'Error', body = null) {
  try {
    if (!response.responseJSON) {
      response.responseJSON = await response.json();
    }

    body = body || response.responseJSON.message;
  } catch (e) {
    /* probably read already */
  }

  new PopupModal({
    buttons: ['Okay'],
    title,
    body,
    html: true,
    dataQaId: 'failedFetchModal',
  }).show();
}
