import urls from 'urls';
import {EviteModal} from './evite_modal';

export class CaptchaDialog {
  constructor(settings) {
    this.settings = {
      width: '520px',
      ...settings,
    };
    if (this.settings.challenge.type === 'sms') {
      this.settings.url = urls.get('sms_captcha');
    } else if (this.settings.challenge.type === 'ad') {
      this.settings.url = urls.get('ad_captcha');
    } else {
      this.settings.url = urls.get('recaptcha');
    }
    if (this.settings.challenge.captcha_event) {
      this.settings.url = `${this.settings.url + this.settings.challenge.captcha_event}/`;
      if (this.settings.challenge.retry) {
        this.settings.url = `${this.settings.url}retry/`;
      }
    }
    this.settings.url_parameters = {
      challenge: this.settings.challenge,
    };
    return new EviteModal(this.settings);
  }
}
