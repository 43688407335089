import {
  UPDATE_INVITATION_SUMMARY,
  DONATION_MADE,
  TURN_ON_DONATION_LOADING,
  TURN_OFF_DONATION_LOADING,
} from './constants';

const initialState = {
  donations: [],
  loading: false,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY: {
      const {donation, donations} = action.results;
      if (donations?.data && donations.data.length >= 1) {
        // new api
        return {
          ...initialState,
          donations: donations.data,
        };
      }
      // old api
      if (!donation || Object.keys(donation) <= 1)
        return {
          ...initialState,
          donations: [],
        };
      if (!Array.isArray(donation)) {
        return {
          ...initialState,
          donations: [donation],
        };
      }
      return {
        ...initialState,
        donations: [...donation],
      };
    }
    case DONATION_MADE:
      const {donationAddedCents, donationUrl} = action.results;
      // const donationAddedCents = action.results;
      const donations = [...state.donations];
      const index = donations.findIndex((d) => d.donation_url === donationUrl);
      if (index >= 0) {
        donations[index].amount_raised_cents += donationAddedCents;
        donations[index].total_donors++;
      }
      return {
        ...state,
        donations,
      };
    case TURN_ON_DONATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TURN_OFF_DONATION_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
