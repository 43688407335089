export const SET_NEW_MESSAGES = 'SET_NEW_MESSAGES';

const initialState = {
  hasNewMessages: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_MESSAGES:
      const hasNewMessages =
        action.responseText !== undefined ? action.responseText.status.has_unread : action.value;
      return {
        hasNewMessages,
      };

    default:
      return state;
  }
}
