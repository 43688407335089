import {FETCH_SINGLE_PHOTO_POST, UPDATE_INVITATION_SUMMARY} from './constants';

const initialState = {
  photos: [],
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      const photos = action.results.photo?.photos;
      if (!photos) return state;

      return {
        ...state,
        photos: photos.map((p) => ({
          url: p.url,
          id: p.id,
        })),
      };
    case FETCH_SINGLE_PHOTO_POST: {
      const {postId} = action;
      const post = action.results;
      if (!post.content?.url) return state;
      if (state.photos.find((p) => p.id === postId)) return state;
      const photos = state.photos.slice(0, 4);
      photos.unshift({url: post.content.url, id: postId});
      return {
        ...state,
        photos,
      };
    }

    default:
      return state;
  }
}
