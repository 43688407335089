import {v4} from 'uuid';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {Typography} from './Typography';
import {PB_CloseLine as PBCloseLine} from '~/common/svg/PB_CloseLine';
import {PB_AlertLine as PBAlertLine} from '~/common/svg/PB_AlertLine';
import {LOG_LEVELS} from '~/common/fabric/constants';
import {largishAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import {PB_InformationCircle as PBInformationCircle} from '~/common/svg/PB_InformationCircle';
import {PB_CheckmarkCircle as PBCheckmarkCircle} from '~/common/svg/PB_CheckmarkCircle';
import {PB_InformationLine as PBInformationLine} from '~/common/svg/PB_InformationLine';

export const toastAlert = (message, options, inToastButtonConfig = {}) => {
  const {icon, type, title, className, toastCallback = null} = options || {};
  let toastFunc = type ? toast[type] : toast;
  if (!toastFunc) {
    evite.log('Error - bad type passed to toastAlert');
    toastFunc = toast;
  }
  const toastId = options.toastId || `toast_${v4()}`;

  return toastFunc(
    <Toast
      type={type}
      icon={icon}
      message={message}
      className={className}
      title={title}
      onDismiss={() => {
        if (toastCallback) toastCallback();
        toast.dismiss(toastId);
      }}
      buttonText={inToastButtonConfig?.text}
      onButtonClick={inToastButtonConfig?.onClick}
      id={options.id}
    />,
    {
      ...options,
      toastId,
      icon: false,
    }
  );
};

const isDesktop = window.matchMedia(`${largishAndUpQuery}`)?.matches;
const iconRatio = isDesktop ? 1 : 0.65;
const ICONS = Object.freeze({
  [LOG_LEVELS.SUCCESS]: <PBCheckmarkCircle ratio={iconRatio} />,
  [LOG_LEVELS.INFO]: <PBInformationCircle ratio={iconRatio} />,
  [LOG_LEVELS.WARNING]: <PBInformationLine ratio={iconRatio} />,
  [LOG_LEVELS.ERROR]: <PBAlertLine ratio={iconRatio} />,
});

/**
 * An improved version of the toast function, so we don't have to recreate a local alert function in every react app.
 * Feel free to add more properties if needed.
 */
export const notify = ({
  title = '',
  message = null,
  type = 'error',
  toastId = null,
  className = '',
  onClose = null,
  buttonText = '',
  buttonOnClick = null,
  options = {},
}) => {
  if (!title && !message) {
    evite.error('Toast created with no title and message!');
    return;
  }
  const level = type in toast ? type : LOG_LEVELS.INFO;
  const icon = ICONS[level];
  const toastFunc = toast[level];
  const id = toastId || `toast_${v4()}`;
  if (!document.getElementById(id)) {
    const body = (
      <Typography as="span" variant="paragraph2">
        {message}
      </Typography>
    );
    toastFunc(
      <Toast
        type={type}
        icon={icon}
        message={body}
        className={className}
        title={title}
        onDismiss={() => {
          if (onClose) onClose();
          toast.dismiss(id);
        }}
        buttonText={buttonText}
        onButtonClick={buttonOnClick}
        id={id}
      />,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        toastId: id,
        icon: false,
        ...options,
      }
    );
  }
};

export const Toast = ({
  id,
  className,
  icon,
  type,
  message,
  title,
  buttonText,
  onButtonClick = null,
  onDismiss = null,
}) => (
  <div className={cx('toast', type, className)} id={id}>
    {icon}
    {title ? (
      <div className="toast__content">
        <Typography variant="paragraph2" className="toast__title">
          {title}
        </Typography>
        <Typography variant="paragraph2" className="toast__message">
          {message}
        </Typography>
      </div>
    ) : (
      <Typography variant="paragraph2" className="toast__message">
        {message}
      </Typography>
    )}
    {buttonText && (
      <button aria-label="action" type="button" onClick={onButtonClick}>
        {buttonText}
      </button>
    )}
    <button
      aria-label="Close Message"
      type="button"
      className="unstyled-button"
      onClick={onDismiss}
    >
      <PBCloseLine ratio={0.75} />
    </button>
  </div>
);

Toast.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(['success', 'information', 'warning', 'error']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onDismiss: PropTypes.func,
};
