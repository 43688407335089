import update from 'immutability-helper';
import {hasWidget} from '../../view_invitation/utils';
import {DONE_SAVE_GIFT_KEYWORDS, GIFT_KEYWORD_PLACEHOLDERS} from './constants';

// Prep the initial state
const initialState = {keywords: []};
function setInitialState() {
  if (window.evite_event) {
    const {gift_keywords} = window.evite_event;
    let len = 0;
    if (gift_keywords) len = gift_keywords.length;
    for (let i = 0; i < Math.max(GIFT_KEYWORD_PLACEHOLDERS.length, len); i++) {
      initialState.keywords.push(i < len ? gift_keywords[i] : '');
    }
  }
}
setInitialState();

export function reducer(state = initialState, action) {
  switch (action.type) {
    case DONE_SAVE_GIFT_KEYWORDS:
      return update(state, {keywords: {$set: [...action.keywords]}});
    default:
      return state;
  }
}
