import {htmlFromString} from '../common/utils';
import {ModalBase} from './base';

export class LoadingModal {
  loadingModalDefaults = {
    message: 'Loading...',
    allowClose: false,
    clickOutside: false,
    width: `${Math.min(window.screen.width - Math.ceil(window.screen.width * 0.05), 420)}px`,
  };

  open = false;

  constructor(settings = {}) {
    if (typeof settings === 'string') {
      settings = {message: settings};
    }
    this.settings = {
      ...this.loadingModalDefaults,
      ...settings,
    };
    const contents = htmlFromString(
      `<div id="loadingModal" class="eviteModalContent"><div class="loadingContainer"><div class="evt-spinner"></div><div class="loader heading">${this.settings.message}</div></div></div>`
    );
    contents.querySelector('.loadingContainer').style.cssText = `
      background: white;
      width: ${this.settings.width};
      height: 233px;
      border: 1px solid #A8A8A8;
      border-radius: 6px;
      overflow: hidden;`;

    this.target = contents;
    return this;
  }

  close = () => {
    if (this.open) {
      this.open = false;
      const modalDom = document.getElementById('loadingModal');
      if (modalDom) {
        // Use this instead of remove for ie11 support
        modalDom.parentNode.removeChild(modalDom);
      }
    }
    return ModalBase.getInstance().close();
  };

  show = (message) =>
    new Promise((resolve, reject) => {
      document.querySelector('body').appendChild(this.target);
      const modal = ModalBase.getInstance();
      modal.initialize(this);
      this.setMessage(message);
      modal.open(`#loadingModal`);
      this.open = true;
      resolve();
    });

  setMessage = (message) => {
    if (message && this.target.querySelector('.loader')) {
      this.target.querySelector('.loader').innerHTML = message;
    }
  };
}
