import {
  EVENT_REMINDERS_UPDATED,
  SET_EVITE_EVENT,
  TURN_ON_READONLY_PREVIEW,
  UPDATE_INVITATION_SUMMARY,
} from './constants';
import {
  fabricDesignWithCrossOriginUpdates,
  fabricDesignWithTextBorderObjectsRemoved,
  getUsedAsTypeName,
  isCanceled,
  isPast,
  isRsvpOff,
} from '~/common/utils/event_utils';

const initialState = {
  eventId: '',
  eventType: '',
  city: '',
  hostName: '',
  title: '',
  hostUserId: '',
  locationName: '',
  messageFromHost: '',
  start: '',
  end: '',
  state: '',
  streetAddress: '',
  zipCode: '',
  hasPassed: false,
  shortLink: '',
  phoneNumber: '',
  status: '',
  usedAs: '', // 'card' or 'invitation' or 'save_the_date'
  rsvpStyle: {yes: '', no: '', maybe: '', who: ''},
  isPremium: null,
  removeAds: null,
  widgets: [],
  pledgeling_data: {},
  videoId: null,
  // Host controls
  reminders: [],
  readonly: false, // Readonly preview mode flag
  // >>>>>> event.options
  allowGifts: !window.isProUser && !window.isPartyPassUser,
  allowInviteMore: false,
  allowGuests: 0,
  allowKids: false,
  maxGuests: 100, // this is the number of *additional* people coming with the invited guest
  maxTotalGuests: 750,
  privateGuestlist: false,
  guestsOfHonor: [],
  // <<<<<< event.options
  /* Lets try not to access _evite_event this directly in components.
   * Either hoist useful stuff to the top level or use selectors to grab stuff */
  _evite_event: {},
  isInvite: true,
  host_name: '',
  sentTo: '',
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_EVITE_EVENT:
      const event = {...action.evite_event, rsvp: undefined};

      const reminders = [];
      if (event.event_reminder_0) {
        reminders.push(event.event_reminder_0);
      }
      if (event.event_reminder_1) {
        reminders.push(event.event_reminder_1);
      }
      return {
        ...state,
        _evite_event: event,
        allowGifts: !!event.options.event_option_show_gifting,
        allowGuests: event.options.event_option_allow_guest_number,
        allowInviteMore: event.options.event_option_allow_invite_more,
        allowKids: event.options.event_option_allow_kids,
        design: event.options.design,
        eventId: event.id,
        eventType: event.event_type,
        /* TODO: Why do we have the design data here, and in the fabricDesign reducer???
         * TODO: Need to double check the embedded create flow preview and make sure removing fabric_design_data
         * TODO: doesn't break something there.
         */
        fabric_design_data: fabricDesignWithTextBorderObjectsRemoved(
          fabricDesignWithCrossOriginUpdates(event.fabric_design_data)
        ),
        hasDonations: Boolean(event.pledgeling_id || event.pledgeling_purpose),
        hasPassed: window.event_has_passed || false,
        isPremium: event.origin === 13 || event.is_fabric_premium,
        maxGuests: event.options.event_option_max_guest_number || 100,
        maxTotalGuests: event.options.event_option_max_guests || 750,
        origin: event.origin,
        pledgeling_data: {
          pledgeling_added: event.pledgeling_added,
          pledgeling_beneficiary: event.pledgeling_beneficiary,
          pledgeling_beneficiary_type: event.pledgeling_beneficiary_type,
          pledgeling_category: event.pledgeling_category,
          pledgeling_goal: event.pledgeling_goal,
          pledgeling_id: event.pledgeling_id,
          pledgeling_purpose: event.pledgeling_purpose,
        },
        privateGuestlist: event.options.event_option_private_guestlist,
        registriesData: event.gift_registries_data,
        reminders,
        removeAds: event.remove_ads || false,
        rsvpOff: event.rsvp_off,
        rsvpStyle: {...event.rsvp_style},
        shortLink: event.shortlink,
        status: event.status,
        title: event.title,
        usedAs: event.used_as,
        videoId: event.event_videos_id,
        widgets: event.widgets,
        isInvite: event.is_invite,
        host_name: event.host_name,
        sentTo: event.sentTo,
      };
    case UPDATE_INVITATION_SUMMARY:
      const details = action.results.event_details;
      if (!details) {
        return {
          ...state,
          reminders: [],
          widgets: [],
          pledgeling_data: {},
        };
      }

      return {
        ...state,
        city: details.city,
        hostName: details.host_name,
        title: details.title,
        hostUserId: details.host_user_id,
        locationName: details.location_name,
        messageFromHost: details.message_from_host,
        start: details.start,
        end: details.end,
        state: details.state,
        streetAddress: details.street_address,
        zipCode: details.zip_code,
        guestsOfHonor: details.guests_of_honor || [],
        phoneNumber: details.host_phone || details.phoneNumber,
      };
    case EVENT_REMINDERS_UPDATED:
      return {
        ...state,
        reminders: [...action.payload],
      };
    case TURN_ON_READONLY_PREVIEW:
      return {
        ...state,
        readonly: true,
      };
    default:
      return state;
  }
}

// Selectors
export function selectEventHasPassed(state) {
  // TODO is this more accurate than isPast(eventDetails.status) from event_utils?
  return state.eventDetails.hasPassed;
}

export function selectRsvpStyle(state) {
  return state.eventDetails.rsvpStyle;
}

export function selectEventHasRsvp(state) {
  return (
    !isRsvpOff(state.eventDetails?.rsvpOff) &&
    !isCanceled(state.eventDetails?.status) &&
    !isPast(state.eventDetails?.status) &&
    !getUsedAsTypeName(state.eventDetails?.usedAs) === 'Card'
  );
}

export function setEviteEvent(evite_event) {
  return {type: SET_EVITE_EVENT, evite_event};
}
