import {createApi} from '@reduxjs/toolkit/query/react';

const customBaseQuery =
  ({baseUrl, headers, credentials}) =>
  async ({url, method, body, params}) => {
    try {
      const response = await evite.fetch(baseUrl + url, {
        method,
        headers,
        credentials,
        body,
        params,
      });
      const data = await response.json();

      return response.ok ? {data} : {error: {status: response.status, data}};
    } catch (error) {
      return {
        error: {
          status: 500,
          data: error,
        },
      };
    }
  };
export const api = createApi({
  reducerPath: 'viewInvitationApi',
  baseQuery: customBaseQuery({
    baseUrl: window.location.origin,
    credentials: 'include',
    headers: {
      'X-CSRFToken': evite.cookie('csrftoken'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: ['Event'],
  endpoints: () => ({}),
});
