import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_InformationLine = ({color = charcoal, className = '', ratio = 1}) => {
  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 29.3333C8.636 29.3333 2.66667 23.364 2.66667 16C2.66667 8.63599 8.636 2.66666 16 2.66666C23.364 2.66666 29.3333 8.63599 29.3333 16C29.3333 23.364 23.364 29.3333 16 29.3333ZM16 26.6667C18.829 26.6667 21.5421 25.5429 23.5425 23.5425C25.5429 21.5421 26.6667 18.829 26.6667 16C26.6667 13.171 25.5429 10.4579 23.5425 8.45752C21.5421 6.45713 18.829 5.33332 16 5.33332C13.171 5.33332 10.4579 6.45713 8.45753 8.45752C6.45714 10.4579 5.33334 13.171 5.33334 16C5.33334 18.829 6.45714 21.5421 8.45753 23.5425C10.4579 25.5429 13.171 26.6667 16 26.6667ZM14.6667 9.33332H17.3333V12H14.6667V9.33332ZM14.6667 14.6667H17.3333V22.6667H14.6667V14.6667Z"
        fill={color}
      />
    </svg>
  );
};
