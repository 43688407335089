import React from 'react';
import {eviteBlue} from '~sass/reinvention_styleguide/base/_exports.sass';

export const ArrowIconReinvention = (props) => {
  const {direction, color = eviteBlue, className = '', ratio = 1, dataId} = props;

  // default dimensions
  const width = 14;
  const height = 24;

  // Use ratio to calculate width and height
  const style = {
    height: `${ratio * height}px`,
    width: `${ratio * width}px`,
  };

  let classNames = className;

  // apparently up & down are reversed?
  switch (direction) {
    case 'up':
      style.transform = 'rotate(90deg)';
      classNames += ' arrow-down';
      break;
    case 'down':
      style.transform = 'rotate(-90deg)';
      classNames += ' arrow-up';
      break;
    case 'left':
      style.transform = 'rotate(180deg)';
      classNames += ' arrow-right';
      break;
    case 'right':
      style.transform = 'rotate(0)';
      classNames += ' arrow-right';
      break;
    default:
      break;
  }

  return (
    <svg
      data-header-id={dataId}
      className={classNames}
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M109.7,68.285a1.553,1.553,0,0,1,2.255-2.137L122.4,76.475a1.651,1.651,0,0,1,0,2.255L111.959,89.176c-1.424,1.424-3.68-.831-2.255-2.255l9.258-9.258Z"
        transform="translate(-109.25 -65.63)"
      />
    </svg>
  );
};
