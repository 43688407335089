import urls from 'urls';
import {EviteModal} from './index';

export class ModalAjaxError {
  settings = {
    url: urls.get('modal_ajax_error'),
    buttons: ['Okay'],
    title: null,
    html: false,
    width: '480px',
    dialog: {},
    url_parameters: {},
  };

  constructor(settings) {
    this.settings = {...this.settings, ...settings};
    this.settings.url_parameters.dialog = {
      body: settings.body || '',
      title: settings.title || this.settings.title,
      buttons: settings.buttons || this.settings.buttons,
      html: settings.html || this.settings.html,
    };

    return new EviteModal(this.settings);
  }
}
