import {CREATE_EDIT_POLL, CREATE_EDIT_WTB} from './constants';
import {INIT_APP} from '~/common/fabric/constants';
import {SET_EVITE_EVENT} from '~/common/fabric/reducers/eviteEvent/constants';

const initialState = [];

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_EDIT_POLL:
      return {
        ...state,
        polls: [...action.payload.polls],
      };
    case CREATE_EDIT_WTB:
      return {
        ...state,
        whatToBrings: [...action.payload.whatToBrings],
      };
    case SET_EVITE_EVENT:
      return {
        ...state,
        widgets: {...action.payload.widgets},
      };
    case INIT_APP:
      return {
        ...state,
        polls: [...action.payload.widgets.polls],
        whatToBrings: [...action.payload.widgets.whatToBrings],
      };
    default:
      return state;
  }
}
