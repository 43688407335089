// Just a super thin wrapper over the cover_data passed in via the window
// Should probably only be used by the invitation display widgets.

const INIT_COVER_DATA = 'ViewInvitation/coverData/init';

const initialState = {};

export function initCoverData(cover_data) {
  return {
    type: INIT_COVER_DATA,
    cover_data,
  };
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_COVER_DATA:
      return {
        ...action.cover_data,
        template: {
          ...action.cover_data.template,
          colors:
            action.cover_data.template.colors.length > 0
              ? action.cover_data.template.colors
              : ['white'],
        },
      };

    default:
      return state;
  }
}
