import {UPDATE_GUESTLIST} from './constants';

const initialState = {
  rsvpCounts: {
    adults_yes: 0,
    adults_maybe: 0,
    kids_yes: 0,
    kids_maybe: 0,
    yes: 0,
    maybe: 0,
    no: 0,
    no_reply: 0,
  },
  rsvpDetails: {
    maybe: [],
    yes: [],
    no_reply: [],
    no: [],
  },
};

export function getGuestOptions(state) {
  const event = state.eventDetails._evite_event;
  return {canGuestsShare: event.options.event_option_allow_invite_more};
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_GUESTLIST:
      const guestList = action.results;
      if (!guestList) return state;

      return {
        ...state,
        rsvpDetails: guestList.rsvp_details,
        rsvpCounts: guestList.rsvp_counts,
      };

    default:
      return state;
  }
}
