import {UPDATE_INVITATION_SUMMARY, WTB_UPDATED} from './constants';

const initialState = {
  participants: [],
  listId: '',
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      const whatToBring = action.results.what_to_bring;

      if (!whatToBring) return {};

      return {
        listId: whatToBring.list_id,
        ...whatToBring,
      };
    case WTB_UPDATED:
      const wtbItems = action.results.items || [];
      const participants = [];

      wtbItems.forEach((item) => {
        if (item.selections.length > 0) {
          item.selections.forEach((s) => {
            participants.push({
              user_id: s.user.id,
              guest_name: s.user.first_name,
              item_id: item.id,
              item: item.name,
              quantity: item.quantity,
            });
          });
        }
      });

      return {
        ...state,
        participants,
      };

      /* TODO: extract the selections from action.results and translate into the participants format */
      return state;
    default:
      return state;
  }
}
