export const INIT_APP = 'CreateFabric/*/Init';
export const SET_EVENT_TO_BACKEND_SNAPSHOT = 'CreateFabric/*/EventToBackendSnapshot';
export const VOLUNTEER_SIGNUP = 'evite/*/VOLUNTEER_SIGNUP';
export const HTTP_STATUS_PAYMENT_REQUIRED = 402;
export const FUNERAL_EVENT_TYPE = 'funeral_memorial_service';

export const LOG_LEVELS = Object.freeze({
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
});

export const UNKNOWN_ERROR_MESSAGE =
  'An unknown error occurred, please try again or contact support.';
