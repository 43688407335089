import React from 'react';
import {useHistory, useLocation} from 'react-router';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {PB_AlertLine} from '~/common/svg/PB_AlertLine';
import {saveReaction} from './api/guest';

export const useRecipientReaction = (eventId, eviteType, guestId, saveReactionCallback) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const submit = async (reaction) => {
    let url = '';

    // If no guest id, don't save anything
    if (!guestId) {
      url = `/frp/rsvp`;
      params.append('reaction', reaction);

      history.replace({pathname: url, search: params.toString()}, location.state);
      return;
    }

    params.append('reaction', reaction);

    if (eviteType === 'card') {
      url = '/success'; // Greeting card reaction confirmation page
    } else if (eviteType === 'event') {
      url = `/frp/rsvp`; // Event reaction confirmation page
      params.append('gid', guestId);
    }

    try {
      const data = await saveReaction(reaction, guestId, eventId);
      saveReactionCallback(data);

      if (!url) {
        throw new Error('Unknown evite type');
      }

      history.replace({pathname: url, search: params.toString()}, location.state);
    } catch (err) {
      toastAlert('Error reacting to card', {
        type: 'error',
        icon: <PB_AlertLine size={0.75} />,
      });

      throw err;
    }
  };

  return {submit};
};
