export const UPDATE_INVITATION_SUMMARY = 'ViewInvitation/*/UpdateInvitationSummary';
export const FETCH_INVITATION_SUMMARY_STARTED = 'ViewInvitation/*/FetchInvitationSummaryStarted';
export const FETCH_INVITATION_SUMMARY_FAILED = 'ViewInvitation/*/FetchInvitationSummaryFailed';
export const UPDATE_GUESTLIST = 'ViewInvitation/*/UpdateGuestList';

export const SUBMIT_RSVP = 'ViewInvitation/*/SubmitRSVP';
export const SUBMIT_RSVP_STARTED = 'ViewInvitation/*/SubmitRSVPStarted';
export const SUBMIT_RSVP_FAILED = 'ViewInvitation/*/SubmitRSVPFailed';

export const SET_EVITE_EVENT = 'ViewInvitation/*/setEviteEvent';

export const GUEST_NOTIFICATIONS_UPDATED = 'ViewInvitation/*/GuestNotificationsUpdated';
export const EVENT_REMINDERS_UPDATED = 'ViewInvitation/*/EventRemindersUpdated';

export const EVENT_OPTIONS_UPDATED = 'ViewInvitation/*/EventOptionsUpdated';

export const DONATION_MADE = 'ViewInvitation/Donations/DonationMade';
export const TURN_ON_DONATION_LOADING = 'ViewInvitation/Donations/TurnOnDonationLoading';
export const TURN_OFF_DONATION_LOADING = 'ViewInvitation/Donations/TurnOffDonationLoading';

export const FETCH_PHOTOS = 'ViewInvitation/*/FetchMorePhotos';
export const FETCH_SINGLE_PHOTO_POST = 'ViewInvitation/*/FetchSinglePhotoPost';
export const UPLOAD_PHOTOS = 'ViewInvitation/*/UploadPhotos';

export const POLL_VOTE_UPDATED = 'ViewInvitaion/Polls/PollVoteUpdated';
export const ADD_STAGED_PHOTO = 'upload_photos/add_staged_file';
export const ADD_PENDING_PHOTO = 'upload_photos/add_pending_file';
export const CLEAR_PENDING_PHOTOS = 'upload_photos/clear_pending_files';
export const TOGGLE_PHOTO_SELECTED = 'upload_photos/toggle_photo_selected';
export const SET_FILE_FAILED = 'upload_photos/set_pending_file_failed_to_stage';

export const FETCH_WTB = 'ViewInvitation/WTB/FetchWhatToBringDetail';
export const WTB_UPDATE_SELECTIONS = 'ViewInvitation/WTB/UpdateSelections';
export const WTB_RESET_SELECTIONS = 'ViewInvitation/WTB/ResetSelections';
export const WTB_TOGGLE_EDITING = 'ViewInvitation/WTB/ToggleEditing';

export const WTB_UPDATED = 'ViewInvitation/WTB/Updated';
export const WTB_REMOVE_USER_SIGNUP = 'ViewInvitation/WTB/RemoveUserSignUp';
export const WTB_ADDED_OTHER = 'ViewInvitation/WTB/AddOtherItem';
export const WTB_REMOVE_OTHER = 'ViewInvitation/WTB/RemoveOtherItem';
export const INIT_PREMIUM_DESIGN = 'ViewInvitation/premiumDesign/INIT';

export const TURN_ON_READONLY_PREVIEW = 'ViewInvitation/preview/turnOnReadonly';
