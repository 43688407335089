import {
  ADD_STAGED_PHOTO,
  ADD_PENDING_PHOTO,
  CLEAR_PENDING_PHOTOS,
  TOGGLE_PHOTO_SELECTED,
  SET_FILE_FAILED,
} from './constants';

const initialState = {
  photosToUpload: [
    // { file: FILE, selected: bool}
  ],
  staged: [
    // { photoId: string, file: FILE, selected: bool},
  ],
  stagedCount: 0,
  errorCount: 0,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PENDING_PHOTO:
      const photosToUpload = state.photosToUpload.slice();

      photosToUpload.push({file: action.file, selected: true});
      return {
        ...state,
        photosToUpload,
      };

    case ADD_STAGED_PHOTO:
      const staged = state.staged.slice();
      if (action.file.failed) {
        staged.push(action.file);
      } else {
        staged.push({
          photoId: action.photoId,
          file: action.file,
          selected: true,
        });
      }

      return {
        ...state,
        staged,
        photosToUpload: state.photosToUpload.filter((p) => p.file !== action.file),
        stagedCount: state.staged.reduce(
          (counter, {selected, failed}) =>
            selected === true && !failed ? (counter += 1) : counter,
          1
        ),
      };

    case SET_FILE_FAILED:
      const stagedWithFailed = state.staged.slice();

      stagedWithFailed.push({
        file: {
          failed: true,
          selected: false,
        },
      });

      return {
        ...state,
        photosToUpload: state.photosToUpload.filter((p) => p.file !== action.file),
        staged: stagedWithFailed,
        stagedCount: state.stagedCount - 1,
        errorCount: state.errorCount + 1,
      };

    case CLEAR_PENDING_PHOTOS:
      return {...state, photosToUpload: [], staged: [], errorCount: 0};

    case TOGGLE_PHOTO_SELECTED:
      const {photoId} = action;
      const newStaged = state.staged.map((p) =>
        p.photoId === photoId ? {photoId, file: p.file, selected: !p.selected} : p
      );

      const stagedCount = newStaged.reduce(
        (counter, {selected}) => (selected === true ? (counter += 1) : counter),
        0
      );

      return {
        ...state,
        staged: newStaged,
        stagedCount,
      };

    default:
      return state;
  }
}
