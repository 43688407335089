import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_QuestionCircle = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_13)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM19.304 15.4521C18.2469 16.3216 17.2533 17.1387 17.2533 18.408V18.576H14.7573V18.192C14.7573 16.2525 16.0588 15.1512 17.221 14.1678C18.1585 13.3746 19.0053 12.658 19.0053 11.64C19.0053 10.248 17.9253 9.312 16.1733 9.312C14.3493 9.312 13.1493 10.44 13.0773 12H10.1973C10.2453 8.856 12.8613 6.912 16.1733 6.912C19.5093 6.912 21.7893 8.544 21.7893 11.544C21.7893 13.4081 20.5056 14.4639 19.304 15.4521ZM14.3253 20.688H17.7813V24H14.3253V20.688Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_13">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
