import {UPDATE_GUESTLIST, FETCH_PHOTOS, FETCH_SINGLE_PHOTO_POST} from './constants';

export const LIKED_POST = 'ViewInvitation/posts/like';
export const UNLIKED_POST = 'ViewInvitation/posts/unlike';
export const POST_REPLY = 'ViewInvitation/posts/reply';
export const EDIT_POST = 'ViewInvitation/posts/edit';
export const DELETE_POST = 'ViewInvitation/posts/delete';

const initialState = {
  /*
  postId: {
    userId: '0000ANACBWOYFIQXQEPK6IHPM3ONOA',
    likedBy: [],
    comment: 'me too! Thanks for hosting!',
    guestName: 'Some Guest',
    viewDate: '',
    when: 'just now',
    parentPostId: null,
    replyIds: [],
    mentions: [],
    postType: 'single_photo',
  },
  */
};

export function selectPost(postId) {
  return (state) => state.posts[postId];
}

function* _rsvpsFromGuestList(details) {
  for (const response of ['yes', 'no', 'maybe']) for (const rsvp of details[response]) yield rsvp;
}

function _extractPost(post, parentId) {
  let replyIds = [];

  if (post.replies && Array.isArray(post.replies)) {
    replyIds = post.replies.map((r) => r.post_id);
  } else if (post.replies && post.replies.objects) {
    replyIds = post.replies.objects.map((r) => r.id);
  }

  let likedBy = [];
  if (Array.isArray(post.liked_by)) likedBy = post.liked_by;
  else if (typeof post.liked_by === 'object') likedBy = Object.keys(post.liked_by);

  let guestName = post.guest_name || post.guestName || '';
  if (!guestName && post.creator)
    guestName = `${post.creator.first_name} ${post.creator.last_name}`.trim();

  return {
    postId: post.id,
    photoUrl: post.post_type === 'single_photo' ? post.content.url : undefined,
    userId: post.user_id || post.creator_id,
    likedBy,
    comment: post.rsvp_comment || post.comment || '',
    guestName,
    creatorName: post.creator ? post.creator.first_name + post.creator.last_name : '',
    viewDate: post.viewDate || post.date_viewed || '',
    when: post.rsvp_when || post.comment_when || post.created || 'just now',
    parentPostId: parentId || String(post.parent_id),
    replyIds,
    mentions: post.mentions || [],
    postType: post.post_type ? post.post_type : '',
  };
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_GUESTLIST:
      // Extract the posts from the guestlist call
      const details = action.results?.rsvp_details;
      if (!details) return state;
      const newPosts = {};
      for (const rsvp of _rsvpsFromGuestList(details)) {
        if (!rsvp.post_id) {
          console.log('No Post id for rsvp', rsvp);
          continue;
        }
        newPosts[rsvp.post_id] = _extractPost(rsvp);
        for (const reply of rsvp.replies || []) {
          newPosts[reply.post_id] = _extractPost(reply, rsvp.post_id);
        }
      }
      return {
        ...state,
        ...newPosts,
      };

    case LIKED_POST: {
      const {postId, userId} = action;
      const post = state[postId];
      return {
        ...state,
        [postId]: {
          ...post,
          likedBy: post.likedBy.concat([userId]),
        },
      };
    }
    case UNLIKED_POST: {
      const {postId, userId} = action;
      const post = state[postId];
      return {
        ...state,
        [postId]: {
          ...post,
          likedBy: post.likedBy.filter((id) => id !== userId),
        },
      };
    }
    case POST_REPLY: {
      const {postId: parentPostId, results: reply} = action;
      const parentPost = state[parentPostId];
      const replyPost = _extractPost(reply);
      const replyId = String(reply.id);
      return {
        ...state,
        [parentPostId]: {
          ...parentPost,
          replyIds: parentPost.replyIds.concat([replyId]),
        },
        [replyId]: replyPost,
      };
    }
    case EDIT_POST: {
      const {postId, results} = action;
      const updatedPost = _extractPost(results);
      return {
        ...state,
        [postId]: updatedPost,
      };
    }
    case DELETE_POST: {
      const {postId} = action;
      const {parentPostId} = state[postId];
      if (!!parentPostId && parentPostId !== 'undefined' && parentPostId !== 'null') {
        const parentPost = state[parentPostId];

        if (parentPost && !parentPost.replyIds) {
          parentPost.replyIds = [];
        }
        const filteredReplies = parentPost ? parentPost.replyIds.filter((id) => id !== postId) : [];
        console.log(
          'just in case: parentPost, parentPostId, filteredReplies',
          parentPost,
          parentPostId,
          filteredReplies
        );
        return {
          ...state,
          [postId]: undefined,
          [parentPostId]: {
            ...parentPost,
            replyIds: filteredReplies || [],
          },
        };
      }
      return {
        ...state,
        [postId]: undefined,
      };
    }

    case FETCH_PHOTOS: {
      const newPosts = {};
      for (const photo of action.results.posts) {
        newPosts[photo.id] = _extractPost(photo);
        for (const reply of photo.replies.objects || []) {
          newPosts[reply.id] = _extractPost(reply, photo.id);
        }
      }

      return {
        ...state,
        ...newPosts,
      };
    }

    case FETCH_SINGLE_PHOTO_POST: {
      const {postId} = action;
      const singlePost = {};
      const post = action.results;

      singlePost[postId] = _extractPost(post);

      for (const reply of post.replies.objects || []) {
        singlePost[reply.id] = _extractPost(reply, postId);
      }

      return {
        ...state,
        ...singlePost,
      };
    }

    default:
      return state;
  }
}
