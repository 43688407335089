import {UPDATE_INVITATION_SUMMARY} from './constants';

const initialState = {
  guestPreviews: [],
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVITATION_SUMMARY:
      const wic = action.results.who_is_coming;
      if (!wic) return state;

      return {
        isPrivateGuestlist: wic.private_guestlist,
        guestPreviews: wic.guest_previews.map((p) => ({
          guestName: p.guest_name,
          adults: p.adults,
          kids: p.kids,
          rsvpComment: p.rsvp_comment,
          rsvpWhen: p.rsvp_when,
          userId: p.user_id,
          guestOfHonor: p.guest_of_honor,
        })),
      };
    default:
      return state;
  }
}
