import {configureStore} from '@reduxjs/toolkit';
import {api} from '~/ViewInvitation/api/api';
import {rootReducer} from './reducers';

export const middlewares = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(api.middleware);

export function createInvitationStore(initialState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: middlewares,
  });
}
