import React from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Arrow} from '~/common/svg/PB_Arrow';

export const MoreDetailsIndicator = ({preview}) => (
  <div
    className={`design-template__more-details-flex-wrapper ${!preview ? 'invitation-margin' : ''}`}
  >
    <Typography
      className="design-template__more-details"
      data-qa-id="more_details_icon"
      variant="label2"
    >
      MORE DETAILS
    </Typography>
    <PB_Arrow className="design-template__more-details-arrow" ratio={0.39} direction="down" />
  </div>
);
